

/**
 * Theme for being used in the business-facing app.
 */

import { colors, palette } from 'ui/themes/colors'

// DO NOT USE THESE COLORS! THEY ARE DEPRECATED AND WILL BE REMOVED IN THE FUTURE
const deprecatedColors = {
  primary: colors['Primary/Base'],
  secondary: colors['Text/Secondary'],
  success: colors['Success/Base'],
  info: colors['Accent1/Base'],
  warning: colors['Warning/Base'],
  danger: colors['Danger/Base'],

  light: palette.grey[20],
  dark: colors['Text/Primary'],

  borderHover: palette.grey[40]
}

export const color = {
  black: palette.black,
  white: palette.white,
  transparent: 'transparent',

  // Ease of use colors, corresponding to the Base color of the palette.
  // e.g. blue =~ colors['Primary/Base']
  blue: palette.blue[60],
  green: palette.green[50],
  orange: palette.orange[50],
  red: palette.red[60],
  purple: palette.purple[60],
  grey: palette.grey[30],

  ...colors,
  ...deprecatedColors
}

const orderStatusColors = (status) => {
  const accent = {
    new: 'Accent2',
    overdue: 'Accent6',
    concept: 'Accent2',
    reserved: 'Accent1',
    started: 'Accent5',
    stopped: 'Accent4',
    archived: 'Accent2',
    canceled: 'Accent2',
    mixed: 'Accent2'
  }[status]

  if (status === 'mixed') {
    return {
      text: colors[`${accent}/Foreground3`],
      color: colors[`${accent}/Foreground3`],
      background: colors[`${accent}/Controlvariant2`],
      border: colors[`${accent}/Outline`]
    }
  } else {
    return {
      text: colors[`${accent}/Foreground3`],
      color: colors[`${accent}/Foreground1`],
      background: colors[`${accent}/Controlvariant1`],
      border: colors[`${accent}/Controlvariant1`]
    }
  }
}

const productStatusColors = (status) => {
  const accent = {
    already_booked: 'Accent6',
    reserved: 'Accent1',
    started: 'Accent5',
    specified: 'Accent2',
    stopped: 'Accent4',
    concept: 'Accent3',
    in_stock: 'Accent4',
    available: 'Accent2',
    unavailable: 'Accent6',
    shortage: 'Accent6',
    overdue: 'Accent6',
    expected: 'Accent3',
    expired: 'Accent3',
    archived: 'Accent3'
  }[status]

  return {
    color: colors[`${accent}/Foreground3`],
    background: colors[`${accent}/Controlvariant2`],
    border: colors[`${accent}/Outline`]
  }
}

const paymentStatusColors = (status) => {
  const accent = {
    'confirmed': 'Accent4',
    'unconfirmed': 'Accent2',
    'payment_due': 'Accent5',
    'revised': 'Accent2',
    'partially_paid': 'Accent1',
    'paid': 'Accent4',
    'overpaid': 'Accent6',
    'process_deposit': 'Accent5',
    'success': 'Accent4',
    'failure': 'Accent6',
    'pending': 'Accent1',
    'failed': 'Accent6',
    'canceled': 'Accent5',
    'sca pending': 'Accent5'
  }[status]

  return {
    color: colors[`${accent}/Foreground1`],
    background: colors[`${accent}/Controlvariant1`],
    border: colors[`${accent}/Controlvariant1`]
  }
}

const units = {
  o: '1px',
  h: '2px',
  1: '6px',
  1.5: '8px',
  2: '12px',
  3: '14px',
  3.5: '16px',
  4: '18px',
  4.5: '20px',
  5: '22px',
  5.5: '24px',
  6: '26px',
  6.5: '28px',
  7: '30px',
  7.5: '32px',
  8: '34px',
  8.5: '36px',
  9: '38px',
  9.5: '40px',
  10: '42px',
  11: '48px',
  12: '50px',
  16: '66px'
}

const font = {
  base: 'proxima-nova',
  fallback: 'sans-serif',
  mono: 'Monospace',
  handwriting: 'Caveat',
  fontAwesomeStyle: 'far',
  size: {
    body: '20px',
    xs: units['2'],
    sm: units['2'],
    md: units['3'],
    mdplus: units['3.5'],
    lg: units['4'],
    lgplus: units['5'],
    xl: units['6'],
    xxl: units['8'],
    xxxl: units['12'],
    largeIcon: units['16']
  },
  weight: {
    light: '300',
    lighter: '400',
    normal: '500',
    semibold: '600',
    bold: '700'
  }
}

font.body = `${font.base}, ${font.fallback}`

const size = {
  borderRadius: {
    xxs: units.h,
    xs: units['1'],
    sm: units['1'],
    md: units['1.5'],
    mdplus: units['2'],
    lg: units['4'],
    xl: units['6'],
    xxl: units['8']
  },
  borderWidth: {
    xs: units.o,
    sm: units.o,
    md: units.o,
    lg: units.o,
    xl: units.o,
    xxl: units.o
  },
  controlHeight: {
    xs: units['6'],
    sm: units['9'],
    md: units['10'],
    lg: units['12'],
    xl: units['16'],
    xxl: units['16']
  },
  margin: {
    xxs: units.h,
    xs: units['1'],
    xsplus: units['1.5'],
    sm: units['2'],
    smplus: units['3'],
    md: units['4'],
    mdplus: units['5'],
    lg: units['6'],
    xl: units['8'],
    xlplus: units['11'],
    xxl: units['12'],
    xxxl: units['16'],
    auto: 'auto'
  },
  padding: {
    xxxs: units.o,
    xxs: units.h,
    xs: units['1'],
    xsplus: units['1.5'],
    sm: units['2'],
    smplus: units['3'],
    md: units['4'],
    mdplus: units['5'],
    lg: units['6'],
    lgplus: units['7'],
    xl: units['8'],
    xlplus: units['11'],
    xxl: units['12'],
    xxxl: units['16']
  },
  shadow: {
    sm: 'rgba(0, 0, 0, 0.2) 0px 1px 2px 0px',
    md: 'rgba(0, 0, 0, 0.1) 0 6px 14px 0',
    lg: 'rgba(0, 0, 0, 0.2) 0px 1px 2px 0px',
    lgplus: 'rgba(0, 0, 0, 0.12) 0px 4px 10px 0px',
    xl: 'rgba(0, 0, 0, 0.12) 0px 8px 20px 0px',
    xxl: 'rgba(0, 0, 0, 0.2) 0px 4px 40px 0px'
  },
  headerHeight: {
    xs: '62px',
    sm: '62px',
    md: '62px',
    lg: '62px'
  },
  navigationWidth: {
    xs: '41px',
    sm: '41px',
    md: '41px',
    lg: '41px'
  },
  navigationWidthExpanded: {
    xs: '201px',
    sm: '201px',
    md: '201px',
    lg: '201px'
  },
  sidebarWidth: {
    xs: '220px',
    sm: '220px',
    md: '220px',
    lg: '220px'
  },
  screenSizes: {
    xs: '0px',
    sm: '768px',
    md: '992px',
    lg: '1200px',
    xl: '1800px'
  }
}

const opacity = {
  disabled: 0.6,
  destroying: 0.4
}

const zIndex = [
  1,
  100,
  200,
  300,
  400
]

export default () => {
  return {
    color,
    font,
    size,
    opacity,
    zIndex,
    orderStatusColors,
    productStatusColors,
    paymentStatusColors,
    units
  }
}
