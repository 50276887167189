import styled, { css } from 'styled-components'
import { color, size, fontSize, fontWeight, contrastingFontColor } from 'ui/themes/utils'

import ButtonElement from 'ui/elements/Button'
import A from 'ui/elements/A'
import Span from 'ui/elements/Span'
import I from 'ui/elements/I'

// Get color for current type, eg: primary
const mainColor = (props) => props.color.indexOf('#') > -1 ? props.color : props.theme.color[props.color]

// Get font color for main color; light font for dark color, dark font for light color
const fontColor = (props) => contrastingFontColor(mainColor(props))

export const Label = styled(Span)``

export const Button = styled(ButtonElement)``
export const Link = styled(A)`
  ${I}:last-child {
    ${(props) => props.target === '_blank' && css`
      margin: 0 0 0 ${size('margin', 'xs')} !important;
    `}
  }
`

export const StatefulIcon = styled(Span)`
  position: absolute;
  left: 0;
  width: 100%;

  i {
    margin: 0 !important;
  }
`

export const ButtonContainer = styled(Span)`
  ${(props) => props.block && css`
    display: block;
    width: 100%;
  `}

  ${(props) => props.inlineBlock && css`
    display: inline-block;
  `}

  ${Button}, ${Link} {
    position: relative;
    display: inline-block;
    vertical-align: middle;

    height: ${size('controlHeight')};
    padding: 0 ${size('padding')};

    color: ${fontColor};
    background-color: ${mainColor};

    font-weight: ${fontWeight('normal')};
    font-size: ${fontSize()};
    text-decoration: none !important;
    text-align: center;
    line-height: ${size('controlHeight')};

    border: none;
    border-radius: ${size('borderRadius')};
    /* To overrule the default borderRadius based on size, which is sometimes to rounded */
    ${(props) => props.borderRadius && css`
      border-radius: ${size('borderRadius', props.borderRadius)};
    `}

    ${Label} {
      color: ${fontColor};
    }

    cursor: pointer;
    outline: none;

    white-space: nowrap;

    &:hover {
      box-shadow: inset 0 0 400px 110px rgba(0, 0, 0, 0.12);
      color: ${fontColor};

      ${Label} {
        color: ${fontColor};
      }
    }

    &:active {
      box-shadow: inset 0 0 400px 110px rgba(0, 0, 0, 0.2);
      color: ${fontColor};

      ${Label} {
        color: ${fontColor};
      }
    }

    ${(props) => props.uppercase && css`
      text-transform: uppercase;
    `}

    ${(props) => props.disabled && css`
      cursor: default;
      opacity: 0.6;
      pointer-events: none;
    `}

    ${(props) => props.unclickable && css`
      pointer-events: none;
    `}

    ${(props) => props.block && css`
      display: block;
      width: 100%;
    `}

    ${(props) => props.textAlign && css`
      text-align: ${(props) => props.textAlign};
    `}

    ${(props) => props.outline && typeof props.outline === 'boolean' && css`
      color: ${fontColor};
      background-color: ${mainColor};
      border: ${size('borderWidth')} solid ${color('Misc/Divider')};

      ${Label} {
        color: ${fontColor};
      }

      &:hover {
        color: ${fontColor};
        box-shadow: none;
        border-color: ${color('borderHover')};

        ${Label} {
          color: ${color('Text/Primary')};
        }
      }
    `}

    ${(props) => props.noBorder && css`
      border-color: transparent
    `}

    ${(props) => props.outline && props.outline === 'transparentWhite' && css`
      color: ${color('Text/Inverted')};
      background-color: transparent;
      border: ${size('borderWidth')} solid ${color('Text/Secondary')};

      ${Label} {
        color: ${color('Text/Inverted')};
      }

      &:hover {
        box-shadow: none;
        border-color: ${color('white')};
      }
    `}

    ${(props) => props.color === 'white' && css`
      border: ${size('borderWidth')} solid ${color('Misc/Divider')};
    `}

    ${(props) => props.color === 'black' && css`
      &:hover {
        box-shadow: inset 0 0 400px 110px rgba(255,255,255, 0.12);
      }
    `}

    /* Icon styling */
    i {
      ${(props) => (!props.iconFloat || props.iconFloat === 'left') && css`
        margin-right: ${size('margin', 'xs')};
      `}
      ${(props) => (props.iconFloat === 'right') && css`
        margin-left: ${size('margin', 'xs')};
      `}
    }

    ${(props) => props.noLabel && css`
      i {
        margin: 0 !important;
      }
    `}

    ${(props) => props.isSubmit && css`
      height: 44px;

      display: flex;
      justify-content: center;
      align-items: center;

      margin: 0 auto;

      background-color: ${color('Text/Primary')};
      border: ${size('borderWidth')} solid ${color('Text/Primary')};

      border-radius: 10px;
      font-size: 16px;

      transition: background 200ms, color 200ms;

      &:hover:not(:disabled) {
        color: ${color('Text/Primary')};

        background-color: #FFFFFF;

        transition: background 200ms, color 200ms;

        box-shadow: none;

        ${Label} {
          color: ${color('Text/Primary')};
        }
      }
    `}

    ${(props) => props.round && css`
      height: auto;

      padding: ${size('padding', 'sm')} ${size('padding', 'xl')};

      font-weight: ${fontWeight('semibold')};
      font-size: 16px;
      line-height: 1.5;

      border-radius: ${size('controlHeight', 'lg')};

      border: ${size('borderWidth')} solid ${color('Primary/Base')};

      transition: background 500ms, color 500ms;

      &:hover:not(:disabled) {
        color: ${color('Primary/Base')};
        background-color: ${color('white')};

        box-shadow: none;

        transition: background 500ms, color 500ms;

        ${Label} {
          color: ${color('Primary/Base')};
        }
      }
    `}

    ${(props) => props.withBranding && css`
      background-color: var(--branding-color, ${mainColor});
    `}
  }
`

export const Hidden = styled(Span)`
  visibility: hidden;
`
