// React
import React, { Component } from 'react'
import PropTypes from 'prop-types'

// Libraries

// Components
import BButton from 'ui/blocks/Button'
import Icon from 'ui/components/Icon'
import Loader from 'ui/components/Loader'

// Shared

/**
 * StatefulButton for displaying loading, success, or error state.
 *
 * @example
 *   <StatefulButton icon="save" state="loading">Save</StatefulButton>
 */
export default class StatefulButton extends Component {
  static displayName = 'StatefulButton'

  static propTypes = {
    state: PropTypes.string,
    children: PropTypes.any,
    color: PropTypes.string,
    icon: PropTypes.any,
    size: PropTypes.string,
    modifiers: PropTypes.oneOfType([
      PropTypes.string,
      PropTypes.object
    ])
  }

  /**
   * Renders one of the states of the button; loading, success, or error.
   */
  render = () => {
    const { state, children, size, icon, modifiers, ...otherProps } = this.props

    let statefulIcon

    let color = this.props.color

    // modifiers assigned to a Button with a state (promise) could flow into the StatefulButton
    const nestedModifiers = typeof modifiers === 'object' ? modifiers : { self: modifiers }

    switch (state) {
      case 'loading':
        statefulIcon = <Loader size={size} />
        break
      case 'success':
        statefulIcon = <Icon icon="check" />
        break
      case 'error':
        color = 'danger'
        statefulIcon = <Icon icon="exclamation-triangle" />
        break
    }

    return (
      <BButton
        {...otherProps}
        textAlign="center"
        unclickable
        disabled={false}
        onClick={null}
        color={color}
        size={size}
        modifiers={nestedModifiers.self}
      >
        <BButton.Button modifiers={nestedModifiers.buttonComponent}>
          <BButton.Hidden>
            {icon && <Icon icon={icon} modifiers={nestedModifiers.icon} />}
            <BButton.Label>{children}</BButton.Label>
          </BButton.Hidden>
          <BButton.StatefulIcon>
            {statefulIcon}
          </BButton.StatefulIcon>
        </BButton.Button>
      </BButton>
    )
  }
}
