import hexToRgb from 'hex-to-rgb'
import insertCss from 'insert-css'

/**
 * Fetches color from theme.
 *
 * @param {String} color
 * @param {Float} alpha (optional)
 * @return {String} color
 */
export const color = (color, alpha) => (props) => {
  const hexColor = props.theme.color[color] || color

  if (alpha !== undefined && alpha >= 0 && alpha < 1) {
    return transparentize(hexColor, alpha)
  }

  return hexColor
}

/**
 * Fetches opacity from theme.
 *
 * @param {String} property
 * @return {String} opacity
 */
export const opacity = (property) => (props) => props.theme.opacity[property]

/**
 * Fetches size from theme, supports size from props or given size.
 *
 * @param {String} property
 * @param {String} size (optional)
 * @example
 *   size('padding')
 *   size('padding', 'sm')
 * @return {String} size
 */
export const size = (property, size) => (props) => props.theme.size[property][size || props.size || 'md']

/**
 * Fetches font size from theme, supports size from props or given size.
 *
 * @param {String} size (optional)
 * @example
 *   fontSize()
 *   fontSize('sm')
 * @return {String} font color
 */
export const fontSize = (size) => (props) => props.theme.font.size[size || props.size || 'md']

/**
 * Fetches font weight from theme by given style
 *
 * @param {String} style
 * @example
 *   fontWeight()
 *   fontWeight('bold')
 * @return {String} font weight
 */
export const fontWeight = (style) => (props) => props.theme.font.weight[style]

/**
 * Returns wether a color is dark or not.
 *
 * @param {String} color
 * @param {Number} opacity
 * @return {Boolean} true or false
 */
export const isDark = (color, opacity = 1) => {
  const rgb = hexToRgb(color || '#ffffff')

  let o = Math.round(((parseInt(rgb[0]) * 299) + (parseInt(rgb[1]) * 587) + (parseInt(rgb[2]) * 114)) / 1000)

  o = o / opacity

  return o <= 170
}

/**
 * Returns the contrasting font color for given background color.
 *
 * @param {String} bgColor
 * @return {String} font color
 */
export const contrastingFontColor = (bgColor) => {
  return (props) => isDark(bgColor) ? props.theme.color['Text/Inverted'] : props.theme.color['Text/Primary']
}

/**
 * Injects CSS into the DOM used in the webstore.
 *
 * @param {String} bgColor
 * @return {String} font color
 */
export function insertCSS (color = '#8fcc40') {
  let fontColor

  if (isDark(color)) {
    fontColor = '#FFFFFF'
  } else {
    fontColor = '#424242'
  }

  // Generic branded colors
  // TODO: Is this still a thing?
  insertCss(`
    .bq-branded, .bq-branded:before, .bq-branded:after {
      background: ${color} !important;
      color: ${fontColor} !important;
    }
  `)
  insertCss(`.bq-branded-font { color: ${color} !important; }`)
  insertCss(`.bq-branded-font-color { color: ${fontColor} !important; }`)
  insertCss(`.bq-branded-border { border-color: ${fontColor} !important; }`)
  insertCss(`.bq-branded-focus:focus { border-color: ${color} !important; }`)
  insertCss(`.bq-branded-border-color { border-color: ${color} !important; }`)
}

/**
 * Make a color transparent by returning an hex color with alpha value.
 *
 * @param {String} color
 * @param {Float} alpha
 * @return {String} hex color with alpha value
 */
export const transparentize = (color, alpha) => {
  const decimal = `0${Math.round(255 * alpha).toString(16)}`.slice(-2).toUpperCase()

  return color + decimal
}

/**
 * Disables that the text can be selected.
 */
export function disableTextSelect () {
  return `
    -webkit-touch-callout: none;
    user-select: none;
  `
}

/**
 * Truncates the text if it would overflow.
 * @param {string} width Width the text should be truncated at
 */
export function truncate (width) {
  const widthRule = width ?
    `width: ${width};` :
    ''

  return `
    ${widthRule}
    white-space: nowrap;
    overflow: hidden;
    text-overflow: ellipsis;
  `
}

/**
 * Checks if given string is a valid hex color
 * @param {string} string to test
 */
export const isValidHexColor = (string) => {
  return /(^#[0-9A-F]{6}$)|(^#[0-9A-F]{3}$)/i.test(string)
}

/**
 * Returns the color related to the order status from the theme.
 * @param {string} status The status of an order.
 * @param {string} type The type of the color.
 */
export const orderStatusColor = (status, type) => (props) => {
  const theme = props.theme.orderStatusColors(status)

  return type ? theme[type] : theme
}

/**
 * Returns the color related to the status of a product from the theme.
 * @param {string} status The status of a product.
 */
export const productStatusColor = (status, type) => (props) => {
  const theme = props.theme.productStatusColors(status)

  return type ? theme[type] : theme
}

/**
 * Returns the color related to the status of a payment from the theme.
 * @param {string} status The status of a payment.
 * @param {string} type The type of the color.
 */
export const paymentStatusColor = (status, type) => (props) => {
  const theme = props.theme.paymentStatusColors(status)

  return type ? theme[type] : theme
}

/**
 * Fetches unit from theme, supports unit from props or given unit.
 *
 * @param {String} property
 * @param {String} unit (optional)
 * @example
 *   unit()
 *   unit('sm')
 * @return {String} unit
 */
export const unit = (unit) => (props) => props.theme.units[unit]

/**
 * Injects modifiers to a css string.
 *
 * @param {Object} modifiers
 * @returns {String} CSS for the given modifiers
 */
export const injectModifiers = (modifiers) => {
  return (props) => {
    if (!props.modifiers) return
    if (typeof props.modifiers !== 'string') return

    const propModifiers = props.modifiers.split(' ')

    let cssString = ''

    Object.keys(modifiers).forEach((key) => {
      if (propModifiers.includes(key)) {
        cssString += modifiers[key](props)
      }
    })

    return cssString
  }
}

/**
 * Mixes two colors by given weight.
 *
 * @param {string} color1 The first color to mix.
 * @param {string} color2 The second color to mix.
 * @param {number} weight The weight to mix the colors by.
 */
export const mixColor = (color1, color2, weight) => {
  color1 = color1.replace('#', '')
  color2 = color2.replace('#', '')

  const d2h = (d) => d.toString(16)
  const h2d = (h) => parseInt(h, 16)

  weight = (typeof (weight) !== 'undefined') ? weight : 50

  let color = '#'

  for (let i = 0; i <= 5; i += 2) {
    const v1 = h2d(color1.substr(i, 2))
    const v2 = h2d(color2.substr(i, 2))
    let val = d2h(Math.round(v2 + (v1 - v2) * (weight / 100.0)))
    while (val.length < 2) { val = `0${val}` }
    color += val
  }

  return color
}

/**
 * Shifts a color by given weight.
 *
 * @param {string} color The color to shift.
 * @param {number} weight The weight to shift the color by.
 */
export const shiftColor = (color, weight) => {
  return (props) => {
    const hex = props.theme.color[color] || color

    return weight > 0 ? mixColor('#000000', hex, weight) : mixColor('#FFFFFF', hex, -weight)
  }
}

export default {
  color,
  opacity,
  size,
  fontSize,
  fontWeight,
  contrastingFontColor,
  isDark,
  insertCSS,
  transparentize,
  disableTextSelect,
  truncate,
  orderStatusColor,
  productStatusColor,
  paymentStatusColor,
  unit,
  injectModifiers
}
