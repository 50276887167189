/**
 * Custom PropType for Promise. It checks if the prop has a `then` function.
 */
const createPromisePropType = (isRequired) => {
  return (props, propName, componentName) => {
    const value = props[propName]
    const error = new Error(
      `Invalid prop '${propName}' supplied to ` +
      `'${componentName}'. Expected a Promise.`
    )

    if (isRequired && !value) {
      return error
    }

    if (value && typeof value.then !== 'function') {
      return error
    }
  }
}

// Optional PropType
const promisePropType = createPromisePropType(false)

// Required PropType
promisePropType.isRequired = createPromisePropType(true)

export default promisePropType
