import styled, { keyframes, css } from 'styled-components'
import { color } from 'ui/themes/utils'

import Span from 'ui/elements/Span'

const shine = keyframes`
  to {
    background-position-x: -200%;
  }
`

export const Skeleton = styled(Span)`
  display: inline-flex;

  background: #eee;
  background: linear-gradient(110deg, ${color('light')} 8%, ${color('Background/Base')} 18%, ${color('light')} 33%);

  vertical-align: middle;
  transform: translateY(-1px);

  max-width: 100%;

  padding: 8px 0;
  margin: 0 auto;

  border-radius: 12px;

  background-size: 200% 100%;

  animation: 1.5s ${shine} linear infinite;

  ${({ string }) => {
    if (string) {
      const split = string.split('.')
      const length = split[split.length - 1].length

      return css`
        width: calc(8px * ${length});
      `
    }
  }}

  ${({ width }) => width && css`
    width: ${width}px;
  `}

  ${({ height }) => height && css`
    height: ${height}px;
  `}

  ${({ noCenter }) => noCenter && css`
    margin: 0;
  `}

  ${({ fullHeight }) => fullHeight && css`
    height: 100%;
  `}

  ${({ fullWidth }) => fullWidth && css`
    width: 100%;
  `}

  ${({ noPadding }) => noPadding && css`
    padding: 0;
  `}
`
